<template>
    <fade-right-animation :element="() => $refs.section">
        <component
            :is="to || href ? 'b-link' : 'section'"
            :to="to"
            :href="href"
            class="hero-banner"
            ref="section"
        >
            <div
                v-if="title"
                class="content-container"
                v-test:contentContainer
            >
                <div class="title-container">
                    <h1
                        class="title"
                        v-test:title
                    >
                        {{ title }}
                    </h1>
                </div>
            </div>

            <component
                :is="title ? 'div' : 'h1'"
                v-if="mobileImage.src || desktopImage.src"
                class="image-container"
                v-test:imageContainer
            >
                <lazy-image
                    v-if="mobileImage.src || desktopImage.src"
                    :src="mobileImage.src ? mobileImage.src : desktopImage.src"
                    :alt="mobileImage.alt ? mobileImage.alt : desktopImage.alt"
                    :width="mobileImage.src ? mobileImage.width : desktopImage.width"
                    :height="mobileImage.src ? mobileImage.height : desktopImage.height"
                    class="mobile-image d-block d-md-none"
                    v-test:mobileImage
                />

                <lazy-image
                    v-if="desktopImage.src"
                    :src="desktopImage.src"
                    :alt="desktopImage.alt"
                    :width="desktopImage.width"
                    :height="desktopImage.height"
                    class="desktop-image d-none d-md-block"
                    v-test:desktopImage
                />
            </component>
        </component>
    </fade-right-animation>
</template>

<script>
    import { BLink } from 'bootstrap-vue';

    export default {
        name: 'hero-banner',

        components: { BLink },

        props: {
            mobileImage: {
                type: Object,
                default: () => ({}),
            },

            desktopImage: {
                type: Object,
                default: () => ({}),
            },

            title: {
                type: String,
                default: '',
            },

            to: {
                type: String,
                default: '',
            },

            href: {
                type: String,
                default: '',
            },
        },
    };
</script>

<style lang="scss" scoped>
    ::v-deep {
        @import "~bootstrap/scss/utilities/display";
    }

    .hero-banner {
        position: relative;
        overflow-x: hidden;

        &:any-link {
            color: inherit;
            transition: filter 0.3s ease;

            &:hover,
            &:focus-visible,
            &:active {
                color: inherit;
                filter: brightness(0.85);
            }
        }
    }

    ::v-deep .lazy-image {
        margin: 0 auto;
        max-width: 100%;
        height: auto;
    }

    .content-container {
        position: absolute;
        width: 80%;
        text-align: center;
        left: 0;
        right: 0;
        margin: auto;
        top: 50%;
        transform: translateY(-50%);

        @include media-breakpoint-up(sm) {
            width: 50%;
        }

        @include media-breakpoint-up(md) {
            top: 30px;
            transform: none;
            width: 75%;
        }

        @include media-breakpoint-up(lg) {
            top: 50px;
            width: 55%;

        }

        @include media-breakpoint-up(xl) {
            width: 40%;
            top: 60px;
        }
    }

    .image-container {
        margin-bottom: 0;
    }
</style>
