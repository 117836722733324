<template>
    <hero-banner
        class="hero-banner-section-module"
        :desktop-image="desktopImage"
        :mobile-image="mobileImage"
        :title="attributes.heroBannerSection_title"
        :to="toValue"
        :href="attributes.link_external"
    />
</template>

<script>
    import HeroBanner from '../../../molecules/HeroBanner/HeroBanner';
    import formatMediaObject from '../../../../utils/formatters/format-media-object';

    export default {
        name: 'hero-banner-section-module',

        components: { HeroBanner },

        props: {
            attributes: {
                type: Object,
                required: true,
                default: () => ({}),
            },
        },

        computed: {
            /**
             * Get desktop background image.
             *
             * @returns {object}
             */
            desktopImage() {
                return formatMediaObject.call(this, this.attributes.desktop_background_image);
            },

            /**
             * Get mobile background image.
             *
             * @returns {object}
             */
            mobileImage() {
                return formatMediaObject.call(this, this.attributes.mobile_background_image);
            },

            /**
             * Get to value.
             *
             * @returns {string}
             */
            toValue() {
                return this.attributes.link.data?.attributes?.uri;
            },
        },
    };
</script>
